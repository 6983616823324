import React from 'react';
import './LogoML.css';
import logo from './Logo_Maren_und_Lukas.png'

const LogoML = () =>{
    return (
        <div className="image-container">
            <img src={logo} alt='Maren & Lukas' />
        </div>
    );
}

export default LogoML;