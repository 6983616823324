import './App.css';
import LogoML from './LogoML';

function App() {
  return (
    <div className="App">
      <LogoML />
      <div className='Text'>
        <h2>Wir heiraten!</h2>
        <p>22. Juni 2024</p>
        <p>Strandhotel Kurhaus Juist</p>
      </div>
    </div>
  );
}

export default App;
